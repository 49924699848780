.content-assembly{
  align-items: center;
  justify-content: center;
  padding: 10px 40px 0 0;
  text-align: center; 
  color: teal;
  width: 100%;
  /* background-color: #f4f7f8; */
  min-height: 100%; 
  padding-bottom: 0px;
}

.header-assembly {
  min-width:210px;
  bottom:0px;
  margin-bottom: 0px;
  padding-bottom: 0px; 
  font-weight: 700;
  font-size: 24px;
  color:#f1f1f1;  
  background-image: linear-gradient( #808080, white);

}
