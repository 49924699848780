  /*https://stackoverflow.com/questions/36191437/css-slideshow-with-fade-in-out-delay*/
  .imgbox1{
    /* display: flex;  */
    align-items: center;
    width:100%;
    min-height: 400px;    
    background-position: center center;
    background-size: cover;
    background-image: url('../../images/test.car1.PNG'); 
  }

  .imgbox2{
    /* display: flex;  */
    align-items: center;
    width:100%;
    min-height: 400px;   
    background-position: center center;
    background-size: cover; 
    background-image: url('../../images/test.car2.PNG');
  }

  .imgbox3{
    /* display: flex;  */
    align-items: center;
    width:100%;
    min-height: 400px;
    background-position: center center;
    background-size: cover; 
    background-image: url('../../images/test.car3.PNG');
  }
  
  .imgbox4{
    /* display: flex;  */
    align-items: center;
    width:100%;
    min-height: 400px;
    background-position: center center;
    background-size: cover; 
    background-image: url('../../images/test.car4.PNG');
  }
 
 
  #img1{
    position: absolute;
    z-index: 1;
    clear:both;
    animation: xfade 15s -0s infinite;
    animation-timing-function: ease-in-out;
  }
  
  #img2{
    position: relative;
    z-index: 2;   
    animation: xfade 15s -5s infinite;
    animation-timing-function: ease-in-out;
  }
  
  #img3{
    position: flex;
    z-index: 3;
    clear:both;
    margin-top:-400px;
    animation: xfade 15s -10s infinite;
    animation-timing-function: ease-in-out;
  }

  #img4{
    position: flex;
    z-index: 4;    
    margin-top:-400px;
    animation: xfade 15s -10s infinite;
    animation-timing-function:ease-in-out;
  }
  
  @keyframes xfade{
  
       0% {opacity: 0;}
      20% {opacity: 1;}
      33% {opacity: 1;}
      53% {opacity: 0;}
      100% {opacity: 0;}
  }

 