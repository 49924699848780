
.findoutmore-img {
    /* <img src="../imagesfromvuejs/findoutmore.png" width="130px" height="30px" >  */
    background: url("../imagesfromvuejs/findoutmore.png") no-repeat center center;
    width:auto;
    height:30px;
    border-radius:60px 
 }
 .placeholder-dashboard{
 width: 160px;
 height: 100px;  
 }
 .m1{
  background: url("../imagesfromvuejs/newspapers.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  font-weight: bolder;
  }
  .m2{
  background: url("../imagesfromvuejs/socialmedias.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  font-weight: bolder;
  }
  
  .m3{
  background: url("../imagesfromvuejs/onlinearticles.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  font-weight: bolder;
  }
  
  .m4{
  background: url("../imagesfromvuejs/tv.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  background: "tvBroadcasts";
  font-weight: bolder;
  }
  
  .m5{
  background: url("../imagesfromvuejs/radio.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  font-weight: bolder;
  }
  
  .m6{
  background: url("../imagesfromvuejs/miscellaneous.png") no-repeat center center;
  margin: 5px 5px 25px 5px;
  border-radius: 50px;
  width: auto;
  height: auto;
  color: white;
  font-weight: bolder;
  }
  
 
 
 .col1 {
 background: url("../imagesfromvuejs/fonddetoile.png")
 }
 
 .col2 {
 
 }
 .vue-dashboard-container {
 color: teal;
 background-color: antiquewhite;
 border-radius:15px;
 width: 100%;
 padding: 20px 0 0 0
 }
 .a{
   color:black;
   width:auto;
   background-color: aliceblue;
   height:30px;
   text-align: center;
   justify-content: center;
   align-content: center;
   border-radius: 40px;
 }

 .imgb{
   border-radius:50px;
   text-align: center;
   justify-content: center;
   align-content: center;
 }
 
 .justify-content-md-center{
    text-align: center;
    justify-content: center;
    align-content: center;
 }