.partyNav {
    width: auto;
    height: auto;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 20px;   
   
    display: flex;
    -webkit-box-pack: justify;   
 
    justify-content: center;
}

.lpParty{
    background-color: #f8ebc9;       
}
.lpParty-Content{
    color: black;
    font-weight: bold; 
    font-size: 16px;
}

.lpParty-link-dlp{
    color:blue
}