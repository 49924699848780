
.bloc-tabs{
    display:flex;
}
.tabs{ 
    padding:5px; 
    width:25%;
    border: solid silver
}
.tab ul li:hover{
    color:black;
    cursor:pointer;
    background-color:white;
}

.tabs:not(:last-child){
    border-right: 1px solid rgba(0,0,0,0.274)
}

.active-tabs{
    background: aqua;
    border-bottom: 1px solid transparent;    
}

.active-tabs::before{
    content:"";
    display:block;
    position:absolute;
    top:-5px;
    left:50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height:5px;
    background: rgb(88,147,241);
}
button{
    border:none
}

.content-tabs {
    flex-grow :1;
}
.content{
    background: white;
    padding:20px;
    width:100%;
    height:100%;
    display:none;
}
.content h2{
    padding:0px 0 5px 0px
}
.content hr{
    width:100px;
    height:2px;
    background: #222;
    margin-bottom:5px;
}

.content p{
    width:100%;
    height:100%;
}
.active-content{
    display:block;
}
